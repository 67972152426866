import { Component, Vue } from "vue-property-decorator";
import { IsDefined, IsNotEmpty, MaxLength, IsEmail, validate } from "class-validator";

import { SideNav } from "@/layout";
import { Lightbox } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { SchoolModel, TeacherModel } from "@/models";
import { Common } from "../Common";

export class TeacherForm{
    @IsDefined() @IsNotEmpty({ message: "必填" }) @MaxLength(50, { message: "最多輸入50字" }) @IsEmail({}, { message: "請輸入有效信箱" }) acc = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) @MaxLength(30, { message: "最多輸入30字" }) name = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) @MaxLength(20, { message: "最多輸入20字" }) phone = '';
    pic = '';
    cid = '';
    sid = '';
    authority = [] ; //checkbox
    active = 1;
}

type response = { [key: string]: unknown };

@Component<AdminTeacherAddController>({
  components: {
    SideNav, Lightbox
  }
})
export default class AdminTeacherAddController extends Vue {
    /*定義data 變數 */
    private doubleClick = false; 
    private teacherForm = new TeacherForm();
    private cityList: object = {};
    private schoolList: { [key: string]: any } = {};
    private branchList: { [key: string]: any } = [];
    private city = "";
    private token = window.localStorage.getItem('adminToken') as string;
    private identity = window.localStorage.getItem('admin_identity') as string;
    
    private errorMsg = "";
    private openLB = false;
    private errNo!: number;
    private errorMsgMap: { [key: string]: string } = {
        acc: '',
        name: '',
        phone: '',
        pic: '',
        branch: '',
    };

    public async created() {
        if(this.identity === "1"){
            this.cityList = await Common.getSchoolCity(); //取得有分校之縣市
        }else{
            const sid = window.localStorage.getItem('admin_sid') as string;
            this.cityList = await Common.getCity(sid); //取得該分校之縣市
            for (const cont of this.cityList as any) {
                this.teacherForm.cid = cont.cid+','+cont.city;
                this.city = cont.city;
            }

            const item: { [key: string]: string } = {
                sid: sid,
                token: this.token,
            }
            const responseData = await SchoolModel.get(item) as response;
            Common.checkToken(responseData.ERR_CODE as number);

            const data = responseData.info as { [key: string]: string };
            this.schoolList[0] =  {
                sid: sid,
                codeName: data.codeName,
                name: data.name,
            };
            this.teacherForm.sid = sid+','+data.codeName+','+data.name;

            // 直接新增目前縣市學校
            this.addBranch();
        }
    }

    //取得該縣市之分校
    private async getSchool() {
        this.teacherForm.sid = '';
        const [cid, city] = this.teacherForm.cid.split(",");
        this.city = city;
        if(this.identity == "1"){ //權限為分校者，不連動分校列表
            this.schoolList = await Common.getSchool(cid);
        }
    }

    //增加老師所屬分校
    private addBranch() {
        const [sid, codeName, name] = this.teacherForm.sid.split(",");
        if(this.city != "" && sid != "0"){
            //需判斷重複新增
            const item = {
              sid: sid,
              codeName: codeName,
              city: this.city,
              name: name,
            }
            this.branchList.push(item);
            this.teacherForm.cid = '';
            this.teacherForm.sid = '';
            this.city = '';
        }else{
            this.errorMsg = "請先選擇縣市及分校";
            this.openLB = true;
        }
    }

    //刪除老師所屬分校
    private delBranch(key: number) {
        if(!this.doubleClick){
            this.doubleClick = true; 

            this.branchList.splice(key, 1);
            this.doubleClick = false;
        }
    }

    private changefile(event: any) {
        const file = event.target.files[0];

        if(file) { //若有上傳圖片
            /* 限制檔案上傳型別 */
            const suffixName = file.name.substring(file.name.lastIndexOf('.') + 1);   // 得到檔案字尾名
            if (suffixName !== 'jpg' && suffixName !== 'png' && suffixName !== 'jpeg') {
                this.errorMsg = '上傳檔案只能是 jpg、png、jpeg 格式！';
                this.openLB = true;
                return;
            }
            /* 限制檔案上傳大小 */
            const isLimitSize = file.size / 1024 / 1024 < 3;
            if (!isLimitSize) {
                this.errorMsg = '上傳檔案不能超過 3MB！';
                this.openLB = true;
                return;
            }
            const reader = new FileReader();

            reader.onload = (e:any) => {
                this.teacherForm.pic = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    }

    private validateAndSubmit() {
        validate(this.teacherForm).then( 
            async result => {
                if(result.length !== 0 || Object.keys(this.branchList).length === 0){
                    this.errorMsgMap = { //先清空error message
                        acc: '',
                        name: '',
                        phone: '',
                        pic: '',
                        branch: '',
                    };
                    result.forEach((item, index) => {
                        const error = item.constraints as {
                            [key: string]: string;
                        };
                        Object.keys(error).forEach(key => {
                            this.errorMsgMap[item.property] = error[key]; //放入error message
                        });
                    });
                    if(Object.keys(this.branchList).length === 0) {
                        this.errorMsgMap['branch'] = '必填';
                    }    
                    return;
                }

                if(!this.doubleClick) {
                    this.doubleClick = true; 

                    const formobj: HTMLFormElement = document.getElementById('teacherForm') as HTMLFormElement;
                    const data = new FormData(formobj); //創建空的Formdata object

                    data.append('authority', JSON.stringify(this.teacherForm.authority));
                    data.append('branchList', JSON.stringify(this.branchList));
                    data.append('token', this.token);

                    this.errNo = await TeacherModel.add(data);
                    Common.checkToken(this.errNo);
                    this.openLB = true;
                    this.doubleClick = false;
                    this.errorMsg = ErrorMessage[this.errNo];
                }
            }
        )
    }

    private closeLB() {
        this.openLB = false;
        if (this.errNo === ErrorCode.Success) {
            this.$router.push("/admin/teacher");
        }
    }
}
